<template>
	<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "rt6lio_AIPractice_page",

	data() {
		return {
			options: [
				{
					title: "app.choose_question.title",
					icon: "question-mark",
					route: {
						name: "rt6lio_AIChooseQuestion",
					},
				},
				{
					title: "app.tools.standard_mind_map.title",
					icon: "mindmap",
					route: {
						name: "rt6lio_AIMindmap",
					},
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
